<template>
  <div>
    <div
      class="input-container"
      :class="{disabled: disabled}">
      <div
        class="top-label sm-text"
        v-if="label && !leftLabel">
        {{ label }}
      </div>

      <div
        class="input-wrapper"
        :class="{flat: flat}">
        <slot name="prepend" />

        <div
          class="input-group"
          :class="{flat: flat}">
          <div
            v-if="label && leftLabel"
            :class="[{'left-label': leftLabel}]"
            class="uih6 grey">
            {{ label }}
          </div>
          <slot name="prepend-inner" />
          <input
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :value="value"
            @input="handleInput"
            @keydown="filterInput"
            :class="{'flat': flat, 'error-border': errorMessage}"
            class="uih6"
            ref="inputElement" />
          <slot name="append-inner" />
          <div
            v-if="copyable"
            class="copy-icon"
            @click="() => copyToClipboard(value, this, 'Copied to clipboard', )">
            <six-icon
              name="copy"
              tooltip="Copy to clipboard"
              clickable></six-icon>
          </div>
        </div>

        <slot name="append" />
      </div>
    </div>
    <div class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import {copyToClipboard} from "../helpers";
export default {
  name: "SixInput",
  components: {
    SixIcon: () => import("./sixIcon.vue"),
  },

  data() {
    return {
      inputValue: "",
    };
  },

  props: {
    value: {
      type: [String, Number],
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    leftLabel: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "text",
      validator: (value) => {
        return ["text", "password", "email", "number"].includes(value);
      },
    },

    copyable: {
      type: Boolean,
      default: false,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: "",
    },

    max: {
      type: Number,
    },

    min: {
      type: Number,
    },
  },

  mounted() {
    this.adjustInputWidth();
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && document.activeElement === this.$refs.inputElement) {
        this.$emit("enter");
      }
    });
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.$emit("esc");
      }
    });
  },

  updated() {
    this.adjustInputWidth();
  },

  methods: {
    copyToClipboard,
    adjustInputWidth() {
      const inputElement = this.$refs.inputElement;
      if (inputElement) {
        const labelWidth = this.$refs.leftLabel
          ? this.$refs.leftLabel.offsetWidth
          : 0;
        const placeholderWidth =
          inputElement.offsetWidth - inputElement.clientWidth;
        inputElement.style.width = `calc(100% - ${labelWidth}px - ${placeholderWidth}px)`;
      }
    },

    handleInput(event) {
      let inputValue = event.target.value;

      if (this.type === "number") {
        const numericValue = Number(inputValue);

        if (this.max !== null && numericValue > this.max) {
          inputValue = this.max;
        }
        if (this.min !== null && numericValue < this.min) {
          inputValue = this.min;
        }
      }
      this.$emit("input", inputValue);

      event.target.value = inputValue;
    },

    filterInput(e) {
      const controlKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ];
      if (this.type === "number") {
        if (controlKeys.includes(e.key)) {
          return; // Allow control keys
        }
        if (!/^[0-9]{0,4}$/.test(String(e.key))) {
          e.preventDefault();
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: var(--font-family);
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.input-group {
  display: flex;
  align-items: stretch;
  width: 100%;
  background: #fff;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}

.top-label {
  border-radius: 4px 0px 0px 4px;
  margin: 4px 0;
}

.left-label {
  border-radius: 4px 0px 0px 4px;
  background: #f2f4f7;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  white-space: nowrap;
}

.copy-icon {
  cursor: pointer;
  background: #fff;
  padding: 6.2px;
  border-left: 1px solid #e9ecef;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

input {
  flex: 4;
  padding: 10px 12px;
  border-radius: 4px;
  border: none;
  background: #fff;
  font-family: var(--font-family);
  line-height: normal;
}

input::placeholder {
  color: var(--color-deactive-text);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

input:disabled {
  color: var(--color-deactive-text);
  cursor: not-allowed;
  background: #e9ecef;
  border-radius: 0px;
}

.flat {
  border: none;
}
/* Removes the arrows from number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.error-message {
  color: var(--color-danger);
  font-size: 14px;
  margin-top: 4px;
}

.error-border {
  border: 1px solid var(--color-danger);
}

@media screen and (max-width: 1536px) {
  .left-label {
    padding: 11px;
  }
  input {
    padding: 10px 12px;
  }
}
</style>

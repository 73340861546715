<template>
  <transition name="slide">
    <div
      v-if="isOpen || !fullWidth"
      :class="{
        'sidebar': true,
        'sidebar-closed': !isOpen,
      }"
      :style="{width: fullWidth ? '100%' : ''}">
      <!-- Group -->
      <div
        class="group"
        v-for="(group, index) in filteredMenu"
        :key="index"
        :style="{width: fullWidth ? '100%' : ''}">
        <!-- Group Title -->
        <transition name="slide-title">
          <h6
            class="group-title"
            v-show="isOpen">
            {{ group.title }}
          </h6>
        </transition>

        <!-- Group Items -->
        <div
          v-for="item in group.items"
          :key="item.name"
          :style="{width: fullWidth ? '100%' : ''}">
          <!-- Group Item (if no children) -->
          <div
            v-if="!item.children"
            :class="{
              'active-item': item.name == activeItem,
              'group-item': true,
            }"
            @click="toggleActive(item.name, item.link)">
            <six-icon
              :name="item.icon"
              :color="activeItem === item.name ? 'white' : ''"
              :tooltip="!isOpen ? item.name : ''"
              clickable
              class="item-icon"></six-icon>
            <h6
              v-if="isOpen"
              :class="{
                'item-name': true,
              }">
              {{ item.name }}
            </h6>
          </div>

          <!-- Group Item (with children) -->
          <div
            v-else
            :class="{
              'active-item': item.children.some(
                (child) => child.name == activeItem
              ),
              'group-item': true,
            }"
            @click="toggleChildren(item)">
            <six-icon
              :color="
                item.children.some((child) => child.name == activeItem)
                  ? 'white'
                  : ''
              "
              :name="item.icon"
              :tooltip="!isOpen ? item.name : ''"
              clickable
              class="item-icon"></six-icon>
            <h6
              class="item-name"
              v-if="isOpen">
              {{ item.name }}
            </h6>
            <six-icon
              v-if="isOpen"
              class="item-toggle-icon"
              :color="
                item.children.some((child) => child.name == activeItem)
                  ? 'white'
                  : ''
              "
              name="rightArrow"
              :style="{
                transform: openItem.name == item.name ? 'rotate(90deg)' : '',
              }">
            </six-icon>
          </div>
          <!-- Children -->
          <div
            v-if="openItem.name == item.name"
            :class="{
              'children-dropdown': !isOpen,
            }">
            <div
              v-for="child in item.children"
              :class="{
                'active-item': child.name == activeItem,
                'child-item': true,
              }"
              :key="child.name"
              @click="toggleActive(child.name, child.link)">
              <six-icon
                :name="child.icon"
                :color="activeItem === child.name ? 'white' : ''"
                class="child-item-icon"></six-icon>
              <h6 class="child-name">{{ child.name }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import hasGroup from "../../utils/hasGroup";

export default {
  data() {
    return {
      openItem: {},

      activeItem: "",

      fullWidth: false,

      menu: [
        // Operations
        {
          title: "Operations",
          items: [
            {
              name: "Overview",
              link: "/",
              icon: "overview",
            },
            {
              name: "Assets",
              link: "/assets",
              icon: "assets",
            },
            {
              name: "Cellular",
              icon: "dataPools",
              children: [
                {
                  name: "Data Pools",
                  link: "/datapools",
                  icon: "circle",
                },
                {
                  name: "Total Data Usage",
                  link: "/usage/total",
                  icon: "circle",
                },
              ],
            },
          ],
        },

        // Developer Tools
        {
          title: "Developer Tools",
          items: [
            {
              name: "API Keys",
              link: "/developer",
              icon: "apiKeys",
            },
            {
              name: "Registry",
              link: "/registry",
              icon: "registry",
            },
          ],
        },

        // Account
        {
          title: "Account",
          items: [
            {
              name: "Billing",
              icon: "billing",
              children: [
                {
                  name: "Add Credits",
                  link: "/billing/addcredits",
                  icon: "circle",
                },
                {
                  name: "Payment Details",
                  link: "/billing",
                  icon: "circle",
                },
                {
                  name: "Cost Summary",
                  link: "/usage/cost-summary",
                  icon: "circle",
                },
              ],
            },
            {
              name: "Help",
              icon: "help",
              children: [
                {
                  name: "FAQ",
                  link: "/faq",
                  icon: "circle",
                },
                {
                  name: "Community",
                  link: "https://community.sixfab.com/c/sixfab-connect/25",
                  icon: "circle",
                },
                {
                  name: "Contact",
                  link: "https://sixfab.com/contact/",
                  icon: "circle",
                },
              ],
            },
          ],
        },
      ],
    };
  },

  computed: {
    isOpen() {
      return this.$store.getters.sidebar.opened;
    },
    filteredMenu() {
      const dynamicMenu = this.menu
        .map((group) => ({
          ...group,
          items: group.items.filter((item) => {
            if (group.title === "Other" && item.name === "Help") {
              return true;
            }
            if (item.children) {
              item.children = item.children.filter((child) =>
                this.hasPermission(this.mapMenuItemToPermission(child.name))
              );
              return item.children.length > 0;
            }
            return this.hasPermission(this.mapMenuItemToPermission(item.name));
          }),
        }))
        .filter((group) => group.items.length > 0);

      if (this.$route.meta.requiresBackofficeToken) {
        dynamicMenu.push({
          title: "Backoffice",
          items: [
            {
              name: "Dashboard",
              link: "/masa",
              icon: "circle",
            },
            {
              name: "Logs",
              link: "/masa/logs",
              icon: "circle",
            },
          ],
        });
      }

      return dynamicMenu;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (this.fullWidth) {
          this.$store.commit("closeSidebar");
        }
        for (const group of this.menu) {
          for (const item of group.items) {
            //'Assets' should be active when asset detail page is active
            if (to.path.includes("/assets/") && item.name === "Assets") {
              this.activeItem = item.name;
              return;
            }
            if (item.link === to.path) {
              this.toggleActive(item.name, item.link);
              return;
            } else {
              this.activeItem = "";
            }
            if (item.children) {
              for (const child of item.children) {
                if (child.link === to.path) {
                  this.toggleActive(child.name, child.link);
                  return;
                }
              }
            }
          }
        }
      },
    },
  },

  created() {
    // for handling resizing of window
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    //for toggle children on outside click
    document.addEventListener("click", (e) => {
      if (this.isOpen) return;
      if (e.target.closest(".group-item")) return;
      this.openItem = {};
    });
  },

  mounted() {
    if (hasGroup("fleet_enabled")) {
      this.menu.map((group) => {
        if (group.title === "Operations") {
          group.items
            .find((item) => item.name === "Cellular")
            .children.push({
              name: "Fleets",
              link: "/fleets",
              icon: "circle",
            });
        }
      });
    }
  },

  methods: {
    toggleChildren(item) {
      if (this.openItem.name == item.name) {
        this.openItem = {};
      } else {
        this.openItem = item;
      }
    },

    toggleActive(itemName, link) {
      if (link) {
        if (link.startsWith("http://") || link.startsWith("https://")) {
          window.open(link, "_blank");
        } else if (this.$route.path !== link) {
          this.$router.push(link);
        }
      }
      this.activeItem = itemName;
    },

    handleResize() {
      if (window.innerWidth < 768) {
        this.fullWidth = true;
      } else {
        this.fullWidth = false;
      }
    },

    mapMenuItemToPermission(menuItemName) {
      const nameToPermissionMap = {
        "Overview": "overview.view",
        "Assets": "assets.view",
        "Data Pools": "datapools.view",
        "Fleets": "fleets.view",
        "Total Data Usage": "usage.view",
        "Cost Summary": "usage.view",
        "Billing": "billing.view",
        "Add Credits": "billing.view",
        "Payment Details": "billing.view",
        "API Keys": "developers.view",
        "Registry": "registry.view",
      };

      return nameToPermissionMap[menuItemName] || menuItemName.toLowerCase();
    },

    hasPermission(resource) {
      const customer = this.$store.getters.customer;
      const {member_of, acting_as} = customer;

      // If not acting as someone else, original user has all permissions
      if (!acting_as) {
        return true;
      }

      // Find the member_of entry for the acting_as email
      const member = member_of.find((member) => member.email === acting_as);

      // If the member is not found, assume no permissions are granted
      if (!member) {
        return false;
      }

      // Check if the permission is explicitly granted
      if (member.permissions && member.permissions.includes(resource)) {
        return true;
      }

      // If permission is not explicitly granted, it is denied
      return false;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  color: #8a92a6 !important;
}

.sidebar {
  display: inline-flex;
  min-height: 100%;
  height: auto;
  width: 257px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--FFFFFF-Bg-1, #fff);
  box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
  border-top: 1px solid var(--e9ecef-Bg-2, #e9ecef);
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: width 0.3s linear;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s linear;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-title-enter-active,
.slide-title-leave-active {
  transition: transform 0.25s linear;
}

.slide-title-enter,
.slide-title-leave-to {
  transform: translateX(-280%);
}

.group {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  position: relative;
}

.group-title {
  color: #adb5bd !important;
  font-weight: 600;
  display: flex;
  width: 100%;
  padding: 12px 16px 8px 32px;
  align-items: center;
  gap: 40px;
  margin-top: 0px;
}

.group-item {
  display: flex;
  min-width: 233px;
  max-width: 100%;
  padding: 8px 24px;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--color-deactive-text);
  border-radius: 4px;
  margin-left: 12px;
  margin-right: 12px;
  transition: min-width 0.3s linear;
}

.item-name {
  width: 100%;
}

.item-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.item-toggle-icon {
  width: 24px;
  height: 24px;
}

.child-item {
  display: flex;
  padding: 8px 24px;
  flex-direction: row;
  margin-left: 36px;
  margin-top: 12px;
  margin-right: 12px;
  align-items: center;
  text-align: right;
  cursor: pointer;
  color: var(--color-deactive-text);
  border-radius: 4px;
  white-space: nowrap;
}

.child-item-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.active-item {
  background: linear-gradient(104deg, #357396 0%, #04324d 159.56%);
}
.active-item > * {
  color: #fff !important;
}

.sidebar-closed {
  width: 60px;
}

.sidebar-closed .group-item {
  min-width: 10px;
  padding: 8px;
  margin-left: 0px;
  margin-right: 0px;
  cursor: pointer;
  display: flex;
  vertical-align: middle;
  border-radius: 4px;
  margin-left: 12px;
  margin-right: 12px;
}

.sidebar-closed .item-icon {
  margin-right: 0px;
}

.children-dropdown {
  position: absolute;
  top: 0px;
  left: 50px;
  background: var(--FFFFFF-Bg-1, #fff);
  color: #04324d;
  box-shadow: 0px 10px 30px 0px rgba(8, 13, 36, 0.433);
  border-radius: 4px;
  width: auto;
  z-index: 100;
  text-align: left;
  align-content: flex-start;
  padding: 8px 8px;
}

.children-dropdown .child-item {
  padding: 5px;
  margin-left: 0px;
  margin-top: 12px;
  margin-right: 12px;
  align-items: center;
  text-align: right;
  cursor: pointer;
  border-radius: 4px;
  white-space: nowrap;
}
</style>

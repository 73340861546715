<template>
  <div
    :class="['chip', colorClass, sizeClass, {outlined, flat}]"
    :style="{...colorStyle, cursor: clickable ? 'pointer' : 'default'}">
    <div class="content">
      <div
        v-if="icon"
        class="icon-container">
        <span>
          <six-icon :name="icon"></six-icon>
        </span>
      </div>

      <slot></slot>

      <span
        v-if="closable"
        class="close-icon"
        @click="close"
        :style="{fontSize: closeIconSize}"
        >&times;</span
      >
    </div>
  </div>
</template>

<script>
import SixIcon from "../components/sixIcon.vue";

export default {
  name: "SixChip",

  components: {SixIcon},

  props: {
    closable: {
      // Whether the chip is closable
      // If true, a close icon will be displayed
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
      validator: (value) =>
        value.startsWith("#") ||
        ["primary", "secondary", "success", "danger"].includes(value),
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => ["small", "medium", "large"].includes(value),
    },
    icon: {
      type: String,
      default: "",
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    colorClass() {
      if (this.color.startsWith("#")) {
        return "color-hex";
      }
      return `color-${this.color}`;
    },
    colorStyle() {
      if (this.color.startsWith("#")) {
        return {backgroundColor: this.color.toString()};
      }
    },
    sizeClass() {
      return `size-${this.size}`;
    },
    closeIconSize() {
      const sizes = {
        small: "12px",
        medium: "14px",
        large: "16px",
      };
      return sizes[this.size];
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.chip {
  display: inline-flex;
  align-items: baseline;
  padding: 8px;
  border: none;
  border-radius: 40px;

  color: var(--color-text);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.content {
  display: flex;
  width: fit-content;
  align-items: center;
}

.size-small {
  font-size: 12px;
  padding: 6px;
}

.size-large {
  font-size: 16px;
  padding: 10px;
}

.color-hex:hover {
  opacity: 0.9;
}

.color-primary {
  background: var(--color-primary-gradient);
  color: var(--color-primary-text);
}

.flat.color-primary {
  background: transparent;
  color: var(--color-primary-text);
}

.color-primary-solid {
  background-color: var(--color-primary-solid);
  color: var(--color-primary-text);
}

.color-secondary {
  background-color: var(--color-secondary);
  color: var(--color-secondary-text);
}

.color-success {
  background-color: var(--color-success);
  color: var(--color-success-text);
}

.color-danger {
  background-color: var(--color-danger);
  color: var(--color-danger-text);
}

.color-billing-blue {
  background-color: var(--color-billing-blue);
  color: var(--color-billing-blue-text);
}

.color-billing-green {
  background-color: var(--color-billing-green);
  color: var(--color-billing-green-text);
}

.color-primary.outlined {
  --button-outline-color: var(--color-primary-solid);
  background: transparent;
  color: var(--color-primary-solid);
}

.color-primary-solid.outlined {
  --button-outline-color: var(--color-primary-solid);
  color: var(--color-primary-solid);
}

.color-secondary.outlined {
  --button-outline-color: #000000;
  color: var(--color-secondary-text);
}

.color-success.outlined {
  --button-outline-color: var(--color-success);
  color: var(--color-success);
}

.color-danger.outlined {
  --button-outline-color: var(--color-danger);
  color: var(--color-danger);
}

.color-billing-blue.outlined {
  --button-outline-color: var(--color-billing-blue);
  color: var(--color-billing-blue);
}

.color-billing-green.outlined {
  --button-outline-color: var(--color-billing-green);
  color: var(--color-billing-green);
}

.color-primary:hover,
.color-primary-solid:hover {
  background-color: var(--color-primary-hover);
}

.color-secondary:hover {
  background-color: var(--color-secondary-hover);
}

.color-success:hover {
  background-color: var(--color-success-hover);
}

.color-danger:hover {
  background-color: var(--color-danger-hover);
}

.color-billing-blue:hover {
  background-color: var(--color-billing-blue-hover);
}

.color-billing-green:hover {
  background-color: var(--color-billing-green-hover);
}

.outlined.color-primary:hover,
.outlined.color-primary-solid:hover {
  border-color: var(--color-primary-hover);
  background-color: var(--color-primary-hover);
  color: var(--color-primary-text);
}

.outlined.color-secondary:hover {
  border-color: var(--color-secondary-hover);
  color: var(--color-primary-text);
}

.outlined.color-success:hover {
  border-color: var(--color-success-hover);
  color: var(--color-primary-text);
}

.outlined.color-danger:hover {
  border-color: var(--color-danger-hover);
  color: var(--color-primary-text);
}

.outlined.color-billing-blue:hover {
  border-color: var(--color-billing-blue-hover);
  color: var(--color-primary-text);
}

.outlined.color-billing-green:hover {
  border-color: var(--color-billing-green-hover);
  color: var(--color-primary-text);
}

.flat.color-primary:hover,
.flat.color-primary-solid:hover {
  background-color: var(--color-primary-hover);
}

.flat.color-secondary:hover {
  background-color: var(--color-secondary-hover);
}

.flat.color-success:hover {
  background-color: var(--color-success-hover);
}

.flat.color-danger:hover {
  background-color: var(--color-danger-hover);
}

.flat.color-billing-blue:hover {
  background-color: var(--color-billing-blue);
}

.flat.color-billing-green:hover {
  background-color: var(--color-billing-green);
}

.close-icon {
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
}

.icon-container {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.outlined {
  border: 1px solid;
  background-color: transparent;
}

.flat {
  background-color: transparent;
}
</style>
